import { Col, Container, Row } from 'react-bootstrap';
import { formatPrice } from 'schema/price.schema';
import { CartTotalProps } from './types';

export const CartTotal = ({
    t,
    hasUnknownPrice,
    initialOrderPrice,
    currentShippingPrice,
    orderConfirmation = false
}: CartTotalProps) => {
    return (
        <Container
            fluid={!orderConfirmation}
            className={`cart-total d-flex flex-column mt-4 mb-4 ${orderConfirmation ? 'p-0' : ''}`}
        >
            <Row>
                <Col>
                    <div className="cart-total--subTotal d-flex justify-content-end">
                        <h3 className="cart-total--title mt-4">
                            {t('pages.cart.subTotal')}
                            {hasUnknownPrice ? <sup>*</sup> : ''}:
                            <span className="cart-total--price-container">{formatPrice(initialOrderPrice)}</span>
                        </h3>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col>
                    <div className="cart-total--shipping d-flex justify-content-end">
                        <h3 className="cart-total--title mt-4">
                            {t('pages.cart.shippingTotal')}
                            <span className="cart-total--price-container">{formatPrice(currentShippingPrice)}</span>
                        </h3>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col>
                    <div className="cart-total--total d-flex justify-content-end mt-3">
                        <h3 className="cart-total--title font-weight-bold mt-3">
                            {t('pages.cart.total')}
                            {hasUnknownPrice ? <sup>*</sup> : ''}:
                            <span className="cart-total--price-container">
                                {formatPrice(String(Number(initialOrderPrice) + Number(currentShippingPrice)))}
                            </span>
                        </h3>
                    </div>
                </Col>
            </Row>
        </Container>
    );
};
